






import { Component, Mixins } from 'vue-property-decorator'
import axios from 'axios'

import RegisterServiceUserForm from '@/partials/forms/Services/RegisterServiceUserForm.vue'
import FlashMessageMixin from '@/mixins/flashMessageMixin'

@Component({
  components: {
    RegisterServiceUserForm
  }
})
export default class UsersToEventCreate extends Mixins(FlashMessageMixin) {
  eventRole: Role = {} as Role

  created () {
    const roleNames = {
      speaker: 'Palestrante',
      subscriber: 'Inscrito',
      sponsor: 'Patrocinador',
      studyReviewer: 'Avaliador',
      organizer: 'Organizador',
      staff: 'Equipe'
    }

    axios.get('roles/event/user')
      .then((response) => {
        this.eventRole = response.data.data.find((eventRole: Role) => eventRole.name === roleNames[this.$route.meta.person as EventPerson])
      })
  }

  attachUserToEvent (user: Record<string, any>) {
    const submitForm = {
      users: [user.user.id],
      confirm: user.confirm
    }
    const eventId = this.$route.params.eventId ?? this.$route.params.id
    axios.post(`event/${eventId}/users/role/${this.eventRole.id}/sync`, submitForm)
      .then(() => {
        const flashMessage = {
          message: `O ${this.eventRole.name} foi adicionado ao evento com sucesso.`,
          isSuccess: true
        }
        const routes = {
          speaker: 'EventSpeakers',
          sponsor: 'EventSponsors',
          subscriber: 'EventSubscribers',
          studyReviewer: 'EventShow',
          organizer: 'EventOrganizers',
          staff: 'EventStaff'
        }

        this.$router.push({ name: routes[this.$route.meta.person as EventPerson], params: { id: this.$route.params.eventId } }, () => {
          this.setFlashMessage(flashMessage)
        })
      })
  }
}
